import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

import axios from "axios"
// import axios from "axios"
const Login = props => {
  //meta title
  document.title = "Login | Flymiles"
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [vererror, setVerError] = useState("")

  useEffect(() => {
    // Reset email and password values when the component mounts
    setEmail("")
    setPassword("")
  }, [])

  function generateRandomCode() {
    const code = Math.floor(1000 + Math.random() * 9000) // Generate a random number between 1000 and 9999
    return code.toString()
  }

  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email || "",
      password: password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    // onSubmit: values => {
    //   dispatch(loginUser(values, props.router.navigate))
    // },
    onSubmit: values => {
      handleLogin(values.email, values.password)
    },
    // onSubmit: onSubmit(),
  })

  const login = (email, password) => {
    const data = { email, password }
    return axios.post("https://flymiles.com.au/adminapi/public/api/login", data)
  }
  const handleLogin = async (email, password) => {
    try {
      const response = await login(email, password)

      const { success, user } = response.data

      if (success && user && user.api_token) {
        const apiToken = user.api_token
        const email = user.email
        const name = user.name

        const authData = {
          API: apiToken,
          EMAIL: email,
          NAME: name,
        }

        const jsonString = JSON.stringify(authData)
        localStorage.setItem("apiToken", jsonString)

        console.log(jsonString)
      }

      // Dispatch the loginUser action with the necessary payload
      dispatch(
        loginUser({ email, password }, () => {
          // Navigate to the dashboard
          props.router.navigate("/dashboard")
        })
      )
      console.log(response.data)
    } catch (error) {
      console.log(error.response.data)
      setVerError(error.response.data.message)
    }
  }
  // const handleLogin = async (email, password) => {
  //   try {
  //     const response = await login(email, password)
  //     const { token } = response.data

  //     // Store the token in local storage or Redux state
  //     localStorage.setItem("token", token)

  //     // Dispatch the loginUser action with the necessary payload and callback
  //     dispatch(
  //       loginUser({ email, password }, () => {
  //         // Navigate to the dashboard
  //         props.router.navigate
  //       })
  //     )

  //     console.log(response.data)
  //   } catch (error) {
  //     console.log(error.response.data)
  //   }
  // }

  // const onSubmit = async values => {
  //   try {
  //     const response = await axios.post(
  //       "https://flymiles.com.au/adminapi/public/api/login",
  //       values
  //     )

  //     const { token } = response.data

  //     localStorage.setItem("token", token)
  //     console.log("Bearer token:", token)

  //     dispatch(loginUser(values, props.router.navigate))

  //     console.log(response.data)
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response.data)
  //     } else {
  //       console.log(error.message)
  //     }
  //   }
  // }

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {vererror ? (
                        <Alert color="danger">{vererror}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          // onChange={validation.handleChange}
                          onChange={e => setEmail(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          // onChange={validation.handleChange}
                          onChange={e => setPassword(e.target.value)}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault()
                                socialResponse("facebook")
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault()
                                socialResponse("google")
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

// const { Readable } = require("stream")

// import PropTypes from "prop-types"
// import React, { useState, useEffect } from "react"

// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Form,
//   Input,
//   FormFeedback,
//   Label,
// } from "reactstrap"

// //redux
// import { useSelector, useDispatch } from "react-redux"
// import { Link } from "react-router-dom"
// import withRouter from "components/Common/withRouter"

// // Formik validation
// import * as Yup from "yup"
// import { useFormik } from "formik"

// // actions
// import { loginUser, socialLogin } from "../../store/actions"

// // import images
// import profile from "assets/images/profile-img.png"
// import logo from "assets/images/logo.svg"

// import axios from "axios"
// // import nodemailer from "nodemailer"
// const nodemailer = require("nodemailer")

// // import axios from "axios"
// const Login = props => {
//   //meta title
//   document.title = "Login | Flymiles"
//   const [email, setEmail] = useState("")
//   const [password, setPassword] = useState("")
//   useEffect(() => {
//     // Reset email and password values when the component mounts
//     setEmail("")
//     setPassword("")
//   }, [])

//   function generateRandomCode() {
//     const code = Math.floor(1000 + Math.random() * 9000) // Generate a random number between 1000 and 9999
//     return code.toString()
//   }
//   // Create a transporter using your email service provider's credentials
//   const transporter = nodemailer.createTransport({
//     host: "smtp.gmail.com",
//     port: 587,
//     secure: false, // true for 465, false for other ports
//     auth: {
//       user: "hamnaraeelofficial@gmail.com",
//       pass: "FBOFLP00",
//     },
//   })

//   // Generate and send the email
//   const code = generateRandomCode()
//   const mailOptions = {
//     from: "hamnaraeelofficial@gmail.com",
//     to: "hrjobs743@gmail.com",
//     subject: "Two-Factor Authentication Code",
//     text: `Your authentication code is: ${code}`,
//   }
//   const dispatch = useDispatch()
//   const validation = useFormik({
//     // enableReinitialize : use this  flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       email: email || "",
//       password: password || "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),
//     // onSubmit: values => {
//     //   dispatch(loginUser(values, props.router.navigate))
//     // },
//     onSubmit: values => {
//       handleLogin(values.email, values.password)
//       transporter
//         .sendMail(mailOptions)
//         .then(info => {
//           console.log("Email sent:", info.response)
//         })
//         .catch(error => {
//           console.log("Error:", error)
//         })
//     },
//     // onSubmit: onSubmit(),
//   })

//   const login = (email, password) => {
//     const data = { email, password }
//     return axios.post("https://flymiles.com.au/adminapi/public/api/login", data)
//   }
//   const handleLogin = async (email, password) => {
//     try {
//       const response = await login(email, password)

//       const { success, user } = response.data

//       if (success && user && user.api_token) {
//         const apiToken = user.api_token
//         const email = user.email
//         const name = user.name

//         const authData = {
//           API: apiToken,
//           EMAIL: email,
//           NAME: name,
//         }

//         const jsonString = JSON.stringify(authData)
//         localStorage.setItem("apiToken", jsonString)

//         console.log(jsonString)
//       }

//       // Dispatch the loginUser action with the necessary payload
//       dispatch(
//         loginUser({ email, password }, () => {
//           // Navigate to the dashboard
//           props.router.navigate("/dashboard")
//         })
//       )
//       console.log(response.data)
//     } catch (error) {
//       console.log(error.response.data)
//     }
//   }
//   // const handleLogin = async (email, password) => {
//   //   try {
//   //     const response = await login(email, password)
//   //     const { token } = response.data

//   //     // Store the token in local storage or Redux state
//   //     localStorage.setItem("token", token)

//   //     // Dispatch the loginUser action with the necessary payload and callback
//   //     dispatch(
//   //       loginUser({ email, password }, () => {
//   //         // Navigate to the dashboard
//   //         props.router.navigate
//   //       })
//   //     )

//   //     console.log(response.data)
//   //   } catch (error) {
//   //     console.log(error.response.data)
//   //   }
//   // }

//   // const onSubmit = async values => {
//   //   try {
//   //     const response = await axios.post(
//   //       "https://flymiles.com.au/adminapi/public/api/login",
//   //       values
//   //     )

//   //     const { token } = response.data

//   //     localStorage.setItem("token", token)
//   //     console.log("Bearer token:", token)

//   //     dispatch(loginUser(values, props.router.navigate))

//   //     console.log(response.data)
//   //   } catch (error) {
//   //     if (error.response) {
//   //       console.log(error.response.data)
//   //     } else {
//   //       console.log(error.message)
//   //     }
//   //   }
//   // }

//   const { error } = useSelector(state => ({
//     error: state.Login.error,
//   }))

//   const signIn = type => {
//     dispatch(socialLogin(type, props.router.navigate))
//   }

//   //for facebook and google authentication
//   const socialResponse = type => {
//     signIn(type)
//   }

//   //handleTwitterLoginResponse
//   // const twitterResponse = e => {}

//   return (
//     <React.Fragment>
//       <div className="account-pages my-5 pt-sm-5">
//         <Container>
//           <Row className="justify-content-center">
//             <Col md={8} lg={6} xl={5}>
//               <Card className="overflow-hidden">
//                 <div className="bg-primary bg-soft">
//                   <Row>
//                     <Col xs={7}>
//                       <div className="text-primary p-4">
//                         <h5 className="text-primary">Welcome Back !</h5>
//                         <p>Sign in to continue to Skote.</p>
//                       </div>
//                     </Col>
//                     <Col className="col-5 align-self-end">
//                       <img src={profile} alt="" className="img-fluid" />
//                     </Col>
//                   </Row>
//                 </div>
//                 <CardBody className="pt-0">
//                   <div>
//                     <Link to="/" className="logo-light-element">
//                       <div className="avatar-md profile-user-wid mb-4">
//                         <span className="avatar-title rounded-circle bg-light">
//                           <img
//                             src={logo}
//                             alt=""
//                             className="rounded-circle"
//                             height="34"
//                           />
//                         </span>
//                       </div>
//                     </Link>
//                   </div>
//                   <div className="p-2">
//                     <Form
//                       className="form-horizontal"
//                       onSubmit={e => {
//                         e.preventDefault()
//                         validation.handleSubmit()
//                         return false
//                       }}
//                     >
//                       {error ? <Alert color="danger">{error}</Alert> : null}

//                       <div className="mb-3">
//                         <Label className="form-label">Email</Label>
//                         <Input
//                           name="email"
//                           className="form-control"
//                           placeholder="Enter email"
//                           type="email"
//                           // onChange={validation.handleChange}
//                           onChange={e => setEmail(e.target.value)}
//                           onBlur={validation.handleBlur}
//                           value={validation.values.email || ""}
//                           invalid={
//                             validation.touched.email && validation.errors.email
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.email && validation.errors.email ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.email}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="mb-3">
//                         <Label className="form-label">Password</Label>
//                         <Input
//                           name="password"
//                           value={validation.values.password || ""}
//                           type="password"
//                           placeholder="Enter Password"
//                           // onChange={validation.handleChange}
//                           onChange={e => setPassword(e.target.value)}
//                           onBlur={validation.handleBlur}
//                           invalid={
//                             validation.touched.password &&
//                             validation.errors.password
//                               ? true
//                               : false
//                           }
//                         />
//                         {validation.touched.password &&
//                         validation.errors.password ? (
//                           <FormFeedback type="invalid">
//                             {validation.errors.password}
//                           </FormFeedback>
//                         ) : null}
//                       </div>

//                       <div className="form-check">
//                         <input
//                           type="checkbox"
//                           className="form-check-input"
//                           id="customControlInline"
//                         />
//                         <label
//                           className="form-check-label"
//                           htmlFor="customControlInline"
//                         >
//                           Remember me
//                         </label>
//                       </div>

//                       <div className="mt-3 d-grid">
//                         <button
//                           className="btn btn-primary btn-block"
//                           type="submit"
//                         >
//                           Log In
//                         </button>
//                       </div>

//                       <div className="mt-4 text-center">
//                         <h5 className="font-size-14 mb-3">Sign in with</h5>

//                         <ul className="list-inline">
//                           <li className="list-inline-item">
//                             <Link
//                               to="#"
//                               className="social-list-item bg-primary text-white border-primary"
//                               onClick={e => {
//                                 e.preventDefault()
//                                 socialResponse("facebook")
//                               }}
//                             >
//                               <i className="mdi mdi-facebook" />
//                             </Link>
//                           </li>
//                           {/*<li className="list-inline-item">*/}
//                           {/*  <TwitterLogin*/}
//                           {/*    loginUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
//                           {/*    }*/}
//                           {/*    onSuccess={this.twitterResponse}*/}
//                           {/*    onFailure={this.onFailure}*/}
//                           {/*    requestTokenUrl={*/}
//                           {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
//                           {/*    }*/}
//                           {/*    showIcon={false}*/}
//                           {/*    tag={"div"}*/}
//                           {/*  >*/}
//                           {/*    <a*/}
//                           {/*      href=""*/}
//                           {/*      className="social-list-item bg-info text-white border-info"*/}
//                           {/*    >*/}
//                           {/*      <i className="mdi mdi-twitter"/>*/}
//                           {/*    </a>*/}
//                           {/*  </TwitterLogin>*/}
//                           {/*</li>*/}
//                           <li className="list-inline-item">
//                             <Link
//                               to="#"
//                               className="social-list-item bg-danger text-white border-danger"
//                               onClick={e => {
//                                 e.preventDefault()
//                                 socialResponse("google")
//                               }}
//                             >
//                               <i className="mdi mdi-google" />
//                             </Link>
//                           </li>
//                         </ul>
//                       </div>

//                       <div className="mt-4 text-center">
//                         <Link to="/forgot-password" className="text-muted">
//                           <i className="mdi mdi-lock me-1" />
//                           Forgot your password?
//                         </Link>
//                       </div>
//                     </Form>
//                   </div>
//                 </CardBody>
//               </Card>
//               <div className="mt-5 text-center">
//                 <p>
//                   Don&#39;t have an account ?{" "}
//                   <Link to="/register" className="fw-medium text-primary">
//                     {" "}
//                     Signup now{" "}
//                   </Link>{" "}
//                 </p>
//                 <p>
//                   © {new Date().getFullYear()} Skote. Crafted with{" "}
//                   <i className="mdi mdi-heart text-danger" /> by Themesbrand
//                 </p>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }

// export default withRouter(Login)

// Login.propTypes = {
//   history: PropTypes.object,
// }
