// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState } from "react"

function DatatableTables3() {
  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState([
    {
      conf: "",
      pickup: "",
      age: "",
      office: "",
      startDate: "",
      salary: "",
    },
  ])
  function getButtonColor(showstatus) {
    if (showstatus == "Schedule") {
      return "yellow"
    } else if (showstatus == "Confirmed") {
      return "green"
    } else if (showstatus == "Cancelled") {
      return "red"
    }
    return "blue" // Default color if none of the conditions match
  }
  const formatDate = date => {
    return moment(date).format("DD/MM/YYYY")
  }
  const fetchData = () => {
    setIsLoading(true)

    const storeToken = localStorage.getItem("apiToken")
    const value = JSON.parse(storeToken)
    const token = value.API
    console.log(token)
    return fetch("https://flymiles.com.au/adminapi/public/api/booking/4", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        // apisetData(data)
        console.log(apidata)
        if (
          apidata.success &&
          apidata.bookings &&
          apidata.bookings.length > 0
        ) {
          const updatedData = [
            ...data, // Keep the existing data
            ...apidata.bookings.slice(0, apidata.length).map(booking => ({
              conf: (
                <div
                  style={{
                    color: "#2196f3",
                  }}
                >
                  {booking.reservationid}
                </div>
              ),
              pickup: (
                <div>
                  <strong>Pickup:</strong>
                  <br />
                  {formatDate(booking.pickup_date)}
                  <br />
                  {booking.pickup_time}
                  <br />
                  <i className="fa fa-fw fa-clock"></i>
                  {booking.duration}
                  <br />
                  <i className="fa fa-fw fa-tachometer-alt"></i>
                  {booking.distance}
                </div>
              ),
              name: (
                <div>
                  <span
                    style={{ textTransform: "uppercase", color: "#F44436" }}
                  >
                    {booking.name}
                  </span>
                  <br />
                  {booking.mobile}
                  <br />
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {booking.veh_type_name}
                  </span>
                  <br />
                  <i className="fa fa-fw fa-user"></i>
                  {booking.pax} -<i className="fa fa-fw fa-briefcase"></i>
                  {booking.luggage}
                </div>
              ),
              pickupdropoff: (
                <div>
                  <strong>Pickup</strong>

                  <br />
                  {booking.pickup_address}
                  <br />
                  <strong>Dropoff</strong>

                  <br />
                  {booking.drop_off}
                  <div
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "#ffffff",
                      padding: "2px 10px",
                    }}
                  >
                    <i
                      style={{ transform: "rotate(-45deg)" }}
                      className="fa fa-fw fa-plane deg45m"
                    ></i>
                    {booking.pick_airline_name} {booking.pick_flight_number}{" "}
                    {booking.showstatus} |
                    <small>
                      {" "}
                      {booking.agent_name} | Supplier Conf no -{booking.groupon}
                    </small>
                  </div>
                </div>
              ),
              status: (
                <div>
                  <button
                    style={{
                      border: "none",
                      borderRadius: "2px",
                      padding: "5px 15px",
                      boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.4)",
                      backgroundColor: getButtonColor(booking.showstatus),
                    }}
                  >
                    {booking.showstatus}
                  </button>
                </div>
              ),

              drivervehicle: (
                <div>
                  <i className="fa fa-fw fa-user"></i>
                  {booking.driver_name}
                  <br />
                  <i className="fa fa-fw fa-car"></i>
                  {booking.vehicle_model}
                  <br />
                  <i className="fa fa-fw fa-phone"></i>
                  {booking.contact_no}
                </div>
              ),
              price: (
                <div>
                  {booking.currency} <br /> {booking.price}
                </div>
              ),
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])
  //   console.log("data", data)

  const columns = useMemo(
    () => [
      {
        Header: "Conf#",
        accessor: "conf",
      },
      {
        Header: "Pickup",
        accessor: "pickup",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Pickup - Dropoff",
        accessor: "pickupdropoff",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "DRIVER & VEHICLE",
        accessor: "drivervehicle",
      },
      {
        Header: "Price",
        accessor: "price",
      },
    ],
    []
  )

  //   const data = [
  //     {
  //       name: "Jennifer Chang",
  //       position: "Regional Director",
  //       age: 28,
  //       office: "Singapore",
  //       startDate: "2010/11/14",
  //       salary: "$357,650",
  //     },
  //     {
  //       name: "Gavin Joyce",
  //       position: "Developer",
  //       age: 42,
  //       office: "Edinburgh",
  //       startDate: "2010/12/22",
  //       salary: "$92,575",
  //     },
  //     {
  //       name: "Angelica Ramos",
  //       position: "Chief Executive Officer (CEO)",
  //       age: 47,
  //       office: "London",
  //       startDate: "2009/10/09",
  //       salary: "$1,200,000",
  //     },
  //     {
  //       name: "Doris Wilder",
  //       position: "Sales Assistant",
  //       age: 23,
  //       office: "Sidney",
  //       startDate: "2010/09/20",
  //       salary: "$85,600",
  //     },
  //     {
  //       name: "Caesar Vance",
  //       position: "Pre-Sales Support",
  //       age: 21,
  //       office: "New York",
  //       startDate: "2011/12/12",
  //       salary: "$106,450",
  //     },
  //     {
  //       name: "Yuri Berry",
  //       position: "Chief Marketing Officer (CMO)",
  //       age: 40,
  //       office: "New York",
  //       startDate: "2009/06/25",
  //       salary: "$675,000",
  //     },
  //     {
  //       name: "Jenette Caldwell",
  //       position: "Development Lead",
  //       age: 30,
  //       office: "New York",
  //       startDate: "2011/09/03",
  //       salary: "$345,000",
  //     },
  //     {
  //       name: "Dai Rios",
  //       position: "Personnel Lead",
  //       age: 35,
  //       office: "Edinburgh",
  //       startDate: "2012/09/26",
  //       salary: "$217,500",
  //     },
  //     {
  //       name: "Bradley Greer",
  //       position: "Software Engineer",
  //       age: 41,
  //       office: "London",
  //       startDate: "2012/10/13",
  //       salary: "$132,000",
  //     },
  //     {
  //       name: "Gloria Little",
  //       position: "Systems Administrator",
  //       age: 59,
  //       office: "New York",
  //       startDate: "2009/04/10",
  //       salary: "$237,500",
  //     },
  //     {
  //       name: "Paul Byrd",
  //       position: "Chief Financial Officer (CFO)",
  //       age: 64,
  //       office: "New York",
  //       startDate: "2010/06/09",
  //       salary: "$725,000",
  //     },
  //     {
  //       name: "Michael Silva",
  //       position: "Marketing Designer",
  //       age: 66,
  //       office: "London",
  //       startDate: "2012/11/27",
  //       salary: "$198,500",
  //     },
  //     {
  //       name: "Tatyana Fitzpatrick",
  //       position: "Regional Director",
  //       age: 19,
  //       office: "London",
  //       startDate: "2010/03/17",
  //       salary: "$385,750",
  //     },
  //     {
  //       name: "Haley Kennedy",
  //       position: "Senior Marketing Designer",
  //       age: 43,
  //       office: "London",
  //       startDate: "2012/12/18",
  //       salary: "$313,500",
  //     },
  //     {
  //       name: "Charde Marshall",
  //       position: "SRegional Director",
  //       age: 36,
  //       office: "San Francisco",
  //       startDate: "2008/10/16",
  //       salary: "$470,600",
  //     },
  //     {
  //       name: "Quinn Flynn",
  //       position: "Support Lead",
  //       age: 22,
  //       office: "Edinburgh",
  //       startDate: "2013/03/03",
  //       salary: "$342,000",
  //     },
  //     {
  //       name: "Jena Gaines",
  //       position: "Office Manager",
  //       age: 30,
  //       office: "London",
  //       startDate: "2008/12/19",
  //       salary: "$90,560",
  //     },
  //     {
  //       name: "Sonya Frost",
  //       position: "Software Engineer",
  //       age: 23,
  //       office: "Edinburgh",
  //       startDate: "2008/12/13",
  //       salary: "$103,600",
  //     },
  //     {
  //       name: "Colleen Hurst",
  //       position: "Javascript Developer",
  //       age: 39,
  //       office: "San Francisco",
  //       startDate: "2009/09/15",
  //       salary: "$205,500",
  //     },
  //     {
  //       name: "Rhona Davidson",
  //       position: "Integration Specialist",
  //       age: 55,
  //       office: "Tokyo",
  //       startDate: "2010/10/14",
  //       salary: "$327,900",
  //     },
  //     {
  //       name: "Herrod Chandler",
  //       position: "Sales Assistant",
  //       age: 59,
  //       office: "San Francisco",
  //       startDate: "2012/08/06",
  //       salary: "$137,500",
  //     },
  //     {
  //       name: "Brielle Williamson",
  //       position: "Integration Specialist",
  //       age: 62,
  //       office: "New York",
  //       startDate: "2012/12/02",
  //       salary: "$372,000",
  //     },
  //     {
  //       name: "Airi Satou",
  //       position: "Accountant",
  //       age: 33,
  //       office: "Tokyo",
  //       startDate: "2008/11/28",
  //       salary: "$162,700",
  //     },
  //     {
  //       name: "Cedric Kelly",
  //       position: "Senior Javascript Developer",
  //       age: 22,
  //       office: "Edinburgh",
  //       startDate: "2012/03/29",
  //       salary: "$433,060",
  //     },
  //     {
  //       name: "Ashton Cox",
  //       position: "Junior Technical Author",
  //       age: 66,
  //       office: "San Francisco",
  //       startDate: "2009/01/12",
  //       salary: "$86,000",
  //     },
  //     {
  //       name: "Garrett Winters",
  //       position: "Accountant",
  //       age: 63,
  //       office: "Tokyo",
  //       startDate: "2011/07/25",
  //       salary: "$170,750",
  //     },
  //     {
  //       name: "Tiger Nixon",
  //       position: "System Architect",
  //       age: 61,
  //       office: "Edinburgh",
  //       startDate: "2011/04/25",
  //       salary: "$320,800",
  //     },
  //   ]

  //meta title
  document.title = "Cancelled Data Table | Flymiles"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Cancelled Data Tables" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          columns={columns}
          data={isLoading ? [] : data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
        {isLoading && <div style={{ fontWeight: "bold" }}>Loading Data...</div>}
      </div>
    </div>
  )
}
DatatableTables3.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables3
