// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState } from "react"

function DatatableTables() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  function getButtonColor(showstatus) {
    if (showstatus == "Schedule") {
      return "yellow"
    } else if (showstatus == "Confirmed") {
      return "green"
    } else if (showstatus == "Cancelled") {
      return "red"
    }
    return "blue" // Default color if none of the conditions match
  }
  const formatDate = date => {
    return moment(date).format("DD/MM/YYYY")
  }
  const fetchData = () => {
    setIsLoading(true)
    const storeToken = localStorage.getItem("apiToken")
    const value = JSON.parse(storeToken)
    const token = value.API
    console.log(token)
    return fetch("https://flymiles.com.au/adminapi/public/api/booking/1", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(apidata => {
        // apisetData(data)
        console.log(apidata)
        if (
          apidata.success &&
          apidata.bookings &&
          apidata.bookings.length > 0
        ) {
          const updatedData = [
            ...data, // Keep the existing data
            ...apidata.bookings.slice(0, apidata.length).map(booking => ({
              conf: (
                <div
                  style={{
                    color: "#2196f3",
                  }}
                >
                  {booking.reservationid}
                </div>
              ),
              pickup: (
                <div>
                  <strong>Pickup:</strong>
                  <br />
                  {formatDate(booking.pickup_date)}
                  <br />
                  {booking.pickup_time}
                  <br />
                  <i className="fa fa-fw fa-clock"></i>
                  {booking.duration}
                  <br />
                  <i className="fa fa-fw fa-tachometer-alt"></i>
                  {booking.distance}
                </div>
              ),
              name: (
                <div>
                  <span
                    style={{ textTransform: "uppercase", color: "#F44436" }}
                  >
                    {booking.name}
                  </span>
                  <br />
                  {booking.mobile}
                  <br />
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    {booking.veh_type_name}
                  </span>
                  <br />
                  <i className="fa fa-fw fa-user"></i>
                  {booking.pax} -<i className="fa fa-fw fa-briefcase"></i>
                  {booking.luggage}
                </div>
              ),
              pickupdropoff: (
                <div>
                  <strong>Pickup</strong>

                  <br />
                  {booking.pickup_address}
                  <br />
                  <strong>Dropoff</strong>

                  <br />
                  {booking.drop_off}
                  <div
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "#ffffff",
                      padding: "2px 10px",
                    }}
                  >
                    <i
                      style={{ transform: "rotate(-45deg)" }}
                      className="fa fa-fw fa-plane deg45m"
                    ></i>
                    {booking.pick_airline_name} {booking.pick_flight_number}{" "}
                    {booking.showstatus} |
                    <small>
                      {" "}
                      {booking.agent_name} | Supplier Conf no -{booking.groupon}
                    </small>
                  </div>
                </div>
              ),
              status: (
                <div>
                  <button
                    style={{
                      border: "none",
                      borderRadius: "2px",
                      padding: "5px 15px",
                      boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.4)",
                      backgroundColor: getButtonColor(booking.showstatus),
                    }}
                  >
                    {booking.showstatus}
                  </button>
                </div>
              ),

              drivervehicle: (
                <div>
                  <i className="fa fa-fw fa-user"></i>
                  {booking.driver_name}
                  <br />
                  <i className="fa fa-fw fa-car"></i>
                  {booking.vehicle_model}
                  <br />
                  <i className="fa fa-fw fa-phone"></i>
                  {booking.contact_no}
                </div>
              ),
              price: (
                <div>
                  {booking.currency} <br /> {booking.price}
                </div>
              ),
            })),
          ]

          setData(updatedData) // Update the state with the updated data array
        }
      })
      .catch(error => {
        // Handle the error
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false) // Set loading state to false after fetching data
      })
  }
  useEffect(() => {
    fetchData()
  }, [])
  //   console.log("data", data)

  const columns = useMemo(
    () => [
      {
        Header: "Conf#",
        accessor: "conf",
      },
      {
        Header: "Pickup",
        accessor: "pickup",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Pickup - Dropoff",
        accessor: "pickupdropoff",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "DRIVER & VEHICLE",
        accessor: "drivervehicle",
      },
      {
        Header: "Price",
        accessor: "price",
      },
    ],
    []
  )

  //meta title
  document.title = "Current Data Table | Flymiles"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Current Data Tables" />
        {/* <Table columns={columns} data={data} /> */}

        <TableContainer
          columns={columns}
          data={isLoading ? [] : data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
        {isLoading && <div style={{ fontWeight: "bold" }}>Loading Data...</div>}
      </div>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
